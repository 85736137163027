<template>
    <div v-loading="loading" class="table-area wrapper-trading" style="padding: 10px">
      <div class="table-top">
      <el-row>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
          <h4 class="heading">Notice</h4>
        </el-col>
    
      </el-row>
      </div>
      <div >
        <el-row>
            <vue-editor
                id="editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="htmlForEditor"
                :editorToolbar="customToolbar"
                >
            </vue-editor>

        </el-row>
        </div>
        <div>
          <br>
            <el-row>
                <el-button type="primary" style="float: right;" :disabled="loading" @click="saveFormData()">save</el-button>
            </el-row>

        </div>

        
  
    </div>
  </template>
  
  <script>
  import { fetchList, upload , update} from "@/api/notice";
  //import getRoleSlug from '@/store/app.js'
  // import { _ } from "vue-underscore";
  import { VueEditor } from "vue2-editor";
  import { mapGetters } from "vuex";
  
  
  
  export default {
    name: "ListPaymentRequest",
    components: {
        VueEditor
    },
    data() {
      return {
        htmlForEditor: "",
        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['image', 'code-block']
          ],
        dialogVisible: false,
        dialogType: "create",
        loading: false,
        search: "",
        listDataTemp: null,
        formData: {},
        formErrors: [],
        listAdmins: {},
        listData: {
          data: {
            item: [],
          },
        },
        tablePagination: {},
        listQuery: {
          type: "",
          status:"",
          page: 1,
          limit: 20,
          search: "",
          search_type: "",
          searchJoin: "or",
          orderBy: "created_at",
          sortedBy: "desc",
        },
      };
    },
    computed: {
      ...mapGetters(["getRoleSlug"]),
    },
    created() {
      this.getList();
      //this.getAdminList();
    },
    methods: {
      handleReset() {
        this.listQuery = {
          type: "customer",
          page: 1,
          limit: 20,
          search: "",
          search_type: "",
          searchJoin: "or",
          orderBy: "created_at",
          sortedBy: "desc",
        },
        this.getList();
      },
      // //////////////////
      // EMIT METHODS
      // //////////////////
      handleChildFormData(data) {
        if (data.dialog_type === "edit") {
          this.handleUpdate(data);
        } else {
          this.handleStore(data);
        }
      },
      
    
      
      // //////////////////
      // GET LIST DATA
      // //////////////////
      getList() {
        //this.loading = true;
        console.log(this.listQuery);
        fetchList(this.listQuery).then((response) => {
            this.htmlForEditor = response.data.data.data;
          this.loading = false;
        });
      },
      saveFormData() {
        let data = {
            data: this.htmlForEditor
        }
        this.loading = true;
        update(data)
            .then((response) => {
            if (response.data.success === true) {
                this.dialogVisible = false;
                this.loading = false;
                this.flashSuccess(response.data.message);
                this.getList();
            } else {
                this.formErrors = this.validationErrors(response.data.message);
            }
            this.loading = false;
            })
            .catch((err) => {
            this.$message.error(err);
            });
      },
      handleImageAdded: async function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
        
        //var formData = new FormData();
        let data = {
            image : await this.toBase64(file)
        }
        
        //formData.append("image", file);
        upload(data).then((response) => {
                console.log(response.data.data, response.data.data.image)
          const url = response.data.data.image; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
        }).catch(err => {
            console.log(err);
        });

            // axios({
            //     url: "https://fakeapi.yoursite.com/images",
            //     method: "POST",
            //     data: formData
            // })
            //     .then(result => {
            //     const url = result.data.url; // Get url from response
            //     Editor.insertEmbed(cursorLocation, "image", url);
            //     resetUploader();
            //     })
            //     .catch(err => {
            //     console.log(err);
            //     });
            // }
     
        },

      
  }
}
  </script>
  