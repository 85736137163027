import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
export function fetchList(query) {
  return request({
    url: '/notice',
    method: 'get',
    params: query
  })
}

export function upload(data) {
  return request({
    url: '/notice/file/upload',
    method: 'post',
    data
  })
}

export function update (data) {
    return request({
      url: `notice/update`,
      method: 'post',
      data
    })
  }










